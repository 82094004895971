import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"

const SignUpPage = () => (
  <Layout>
    <SEO title="Become a Whiskey Thief" />
    <form name="contact" method="POST" data-netlify="true">
      <input type="text" name="first name" />
      <input type="text" name="last name" />
      <input type="email" name="email" />
      <input
        class="birthday "
        type="text"
        pattern="[0-9]*"
        value=""
        placeholder="MM"
        size="2"
        maxlength="2"
        name="month"
        id="mce-BDAYNEW-month"
      />
      <input
        class="birthday "
        type="text"
        pattern="[0-9]*"
        value=""
        placeholder="DD"
        size="2"
        maxlength="2"
        name="day"
        id="mce-BDAYNEW-day"
      />
      <div>
      <strong>Are you over 21 </strong>
      <ul>
        <li>
          <input type="radio" value="Yes" name="MMERGE3" id="mce-MMERGE3-0" />
          <label for="mce-MMERGE3-0">Yes</label>
        </li>
        <li>
          <input type="radio" value="No" name="MMERGE3" id="mce-MMERGE3-1" />
          <label for="mce-MMERGE3-1">No</label>
        </li>
      </ul>
      </div>
      <input
        type="submit"
        value="Become a Thief"
        name="subscribe"
        id="mc-embedded-subscribe"
        class="button"
      ></input>
    </form>
  </Layout>
)

export default SignUpPage
